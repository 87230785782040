<script>
import { bonus_extension } from "@/api/my";
import { kglj } from "@/api/my";
import Clipboard from "clipboard";
export default {
    name:'PromotionKickbackList',
    components: {
        Header: () => import('components/base/Header'),
    },
    data() {
        return {
            result: "",
            grandpa_id: "",
            parent_id: "1",
            list: [],
            loading_data: true,
            page: 1,
            pageSize: 20,
            totalPage: "",

            header: {
                mainTitle: getWord('promotion2'),
            },
        };
    },
    methods: {
        loadMore_data() {
            if (this.page >= this.totalPage) {
                return false;
            } else {
                this.page++;
                this.mybonus_extension();
            }
        },
        //推广红利
        mybonus_extension() {
            bonus_extension(
                this.grandpa_id,
                this.parent_id,
                this.page,
                this.pageSize
            ).then(result => {
                if (result.data.code == "SUCCESS") {
                    var data=result.data.result.list;
                    data.forEach(item=>{
                        this.list.push(item);
                    })

                    if (Object.keys(this.list).length===0) {
                        this.list=[];
                        this.list.push({
                            errorMsg:getWord('no_result_of_promotion_benefit')
                        })
                    }

                    this.totalPage = result.data.result.totalPage;
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            });
            this.loading_data = false;
        },
    },
    mounted() {
        this.mybonus_extension();
        this.CustomScroll(this.$refs.promotionBonus.id);
    }
};
</script>

<template>
    <section id="promotion-bonus" ref="promotionBonus" :class="_TEMPLATE">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
            <h3>{{ getWord('promotion_bonus') }}</h3>
            <table
                class="main_table"
                cellspacing="0px"
                v-infinite-scroll="loadMore_data"
                infinite-scroll-disabled="loading_data"
                infinite-scroll-distance="50"
            >
                <tr>
                    <th>{{ getWord('date') }}</th>
                    <th>{{ getWord('referral') }}</th>
                    <th>{{ getWord('referral_deposit') }}</th>
                    <th>{{ getWord('commission2') }}</th>
                </tr>
                <tr v-for="item in list" v-if="!list[0].errorMsg">
                    <td>
                        {{new Date(item.created_at*1000).toLocaleDateString().replace(/\//g, "-")}}
                        <br />
                        {{new Date(item.created_at*1000).toTimeString().substr(0, 8)}}
                    </td>
                    <td>{{item.name}}</td>
                    <td>{{item.pay_price}}</td>
                    <td>{{item.pre_price1 && Number(item.pre_price1).toFixed(2)}}</td>
                </tr>
                <tr v-else>
                    <td class="error" colspan="4">{{item.errorMsg}}</td>
                </tr>
            </table>
    </section>
</template>

<style scoped lang='scss' style="text/css">
#promotion-bonus {
    overflow:auto;    

    &.template-3 {
        height: 100%;
        background-color: #EEEEEE;

        h3 {
            color: #B0B0B0;
            font-size: .3rem;
            padding: .25rem;
        }
    }

    .main_table {
        width: 100%;
        border-collapse: collapse;
        font-size: 0.28rem;
        text-align: center;
        background-color: white;
        color: #666666;
    }
    .main_table tr th {  
        padding: .25rem 0;      
        font-weight: normal;
        
    }
    .main_table tr td {
        border-top: 1px solid #B0B0B0;
        color: #333333;
        padding: .1rem 0;

        &.error {
            padding:.25rem 0;
            color: #B0B0B0;
        }
    }
}


</style>